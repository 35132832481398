// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/IkshF7F55";

const enabledGestures = {PaWcvvsuJ: {hover: true}};

const cycleOrder = ["PaWcvvsuJ"];

const serializationHash = "framer-NPoFr"

const variantClassNames = {PaWcvvsuJ: "framer-v-1nfr73i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const isSet = (value) => {
    return value !== undefined && value !== null && value !== "";
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({color, colorHover, height, id, link, newTab, tap, title, width, ...props}) => { return {...props, Fy_8CUSlf: newTab ?? props.Fy_8CUSlf, jdYUhzTAq: color ?? props.jdYUhzTAq ?? "var(--token-e823f74f-9033-4945-a2cd-50354c7cdc58, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", Jp2gFNnRZ: tap ?? props.Jp2gFNnRZ, Ta9mUlP1x: colorHover ?? props.Ta9mUlP1x ?? "var(--token-3070a07a-a5cf-45eb-af38-7f6ebdd9dbe7, rgb(195, 245, 60))", UnLDJ5teF: link ?? props.UnLDJ5teF, xhAUTwL9f: title ?? props.xhAUTwL9f ?? "Menu"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any;title?: string;link?: string;newTab?: boolean;color?: string;colorHover?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Jp2gFNnRZ, xhAUTwL9f, UnLDJ5teF, Fy_8CUSlf, jdYUhzTAq, Ta9mUlP1x, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PaWcvvsuJ", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapk6o9i6 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (Jp2gFNnRZ) {
const res = await Jp2gFNnRZ(...args);
if (res === false) return false;
}
})

const visible = isSet(xhAUTwL9f)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
{visible && (<Transition value={transition}><Link href={UnLDJ5teF} openInNewTab={Fy_8CUSlf}><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1nfr73i", className, classNames)} framer-t3cwdt`} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"PaWcvvsuJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapk6o9i6} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"PaWcvvsuJ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-12bpno1"} data-styles-preset={"IkshF7F55"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-jdYUhzTAq-HLj6LZx_7))"}}>Menu</motion.p></React.Fragment>} className={"framer-1h69i43"} data-framer-name={"Menu"} layoutDependency={layoutDependency} layoutId={"TNY2ejqk9"} style={{"--extracted-r6o4lv": "var(--variable-reference-jdYUhzTAq-HLj6LZx_7)", "--variable-reference-jdYUhzTAq-HLj6LZx_7": jdYUhzTAq, "--variable-reference-Ta9mUlP1x-HLj6LZx_7": Ta9mUlP1x}} text={xhAUTwL9f} variants={{"PaWcvvsuJ-hover": {"--extracted-r6o4lv": "var(--variable-reference-Ta9mUlP1x-HLj6LZx_7)", "--variable-reference-Ta9mUlP1x-HLj6LZx_7": Ta9mUlP1x}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"PaWcvvsuJ-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-12bpno1"} data-styles-preset={"IkshF7F55"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-Ta9mUlP1x-HLj6LZx_7))"}}>Menu</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition>)}
</LayoutGroup>)

});

const css = [".framer-NPoFr[data-border=\"true\"]::after, .framer-NPoFr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NPoFr.framer-t3cwdt, .framer-NPoFr .framer-t3cwdt { display: block; }", ".framer-NPoFr.framer-1nfr73i { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 23px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-NPoFr .framer-1h69i43 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NPoFr.framer-1nfr73i { gap: 0px; } .framer-NPoFr.framer-1nfr73i > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-NPoFr.framer-1nfr73i > :first-child { margin-left: 0px; } .framer-NPoFr.framer-1nfr73i > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"jH5J1pqAj":{"layout":["auto","fixed"]}}}
 * @framerVariables {"Jp2gFNnRZ":"tap","xhAUTwL9f":"title","UnLDJ5teF":"link","Fy_8CUSlf":"newTab","jdYUhzTAq":"color","Ta9mUlP1x":"colorHover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerHLj6LZx_7: React.ComponentType<Props> = withCSS(Component, css, "framer-NPoFr") as typeof Component;
export default FramerHLj6LZx_7;

FramerHLj6LZx_7.displayName = "Link / Nav Menu";

FramerHLj6LZx_7.defaultProps = {height: 22, width: 40};

addPropertyControls(FramerHLj6LZx_7, {Jp2gFNnRZ: {title: "Tap", type: ControlType.EventHandler}, xhAUTwL9f: {defaultValue: "Menu", displayTextArea: false, title: "Title", type: ControlType.String}, UnLDJ5teF: {title: "Link", type: ControlType.Link}, Fy_8CUSlf: {defaultValue: false, title: "New Tab?", type: ControlType.Boolean}, jdYUhzTAq: {defaultValue: "var(--token-e823f74f-9033-4945-a2cd-50354c7cdc58, rgb(255, 255, 255)) /* {\"name\":\"White\"} */", title: "Color", type: ControlType.Color}, Ta9mUlP1x: {defaultValue: "var(--token-3070a07a-a5cf-45eb-af38-7f6ebdd9dbe7, rgb(195, 245, 60))", title: "Color:hover", type: ControlType.Color}} as any)

addFonts(FramerHLj6LZx_7, [...sharedStyle.fonts])